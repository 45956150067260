<template>
    <!-- 填写基本信息  -->
    <div
        class="attestation"
        v-loading="loading"
        id="scrollTop"
    >
        <top-nav></top-nav>
        <header-guide @winscroll="winScroll"></header-guide>
        <bread-crumb v-if="activeStepNum != 3"></bread-crumb>

        <section
            class="secton publish_sec"
            :class="{'publish_sec_prev': preview }"
        >
            <!-- publish_sec_prev 是否为预览模式 -->
            <div class="form-panner base-info-panner">
                <div
                    class="form-panner-bd bi-bd"
                    :class="{'width-100': activeStepNum == 3 }"
                >
                    <div class="form-cont pb-50">
                        <el-form
                            ref="validRulers"
                            :rules="validRulers"
                            :model="formdDatas"
                            label-position="left"
                            :show-message="false"
                            :disabled="preview"
                            :hide-required-asterisk="preview"
                        >
                            <el-row
                                :gutter="20"
                                style="padding:0px;margin:0px;"
                            >
                                <div
                                    class="form-basisinfo steps"
                                    v-if="activeStepNum !=3"
                                >
                                    <!--  第一步 填写项目信息  -->
                                    <div class="info-card-panner info_card_panner">
                                        <header class="panner-hd">
                                            <h2 class="tit">项目信息</h2>
                                        </header>
                                        <div class="panner-bd user-introduce">
                                            <el-col :span="24">
                                                <el-form-item
                                                    label="项目名称"
                                                    class="need-filed"
                                                    prop="task_name"
                                                    name="task_name"
                                                >
                                                    <el-input
                                                        class="width-100"
                                                        v-model="formdDatas.task_name"
                                                        clearable
                                                        placeholder="请输入项目名称"
                                                        :autofocus="true"
                                                        maxlength="50"
                                                        @change="changeNode('do_task_name')"
                                                    ></el-input>
                                                </el-form-item>
                                            </el-col>

                                            <el-col :span="24">
                                                <el-form-item
                                                    label="服务类型"
                                                    class="need-filed"
                                                    prop="speciality_type"
                                                    name="speciality_type"
                                                >
                                                    <el-select
                                                        class="width-100"
                                                        v-model="formdDatas.speciality_type"
                                                        placeholder="请选择服务类型"
                                                        @change="changeNode"
                                                    >
                                                        <el-option
                                                            v-for=" (items,indexs) in serversTypeOptions"
                                                            :label="items.name"
                                                            :value="items.id"
                                                            :key="indexs"
                                                        ></el-option>
                                                    </el-select>
                                                    <el-tooltip
                                                        class="item"
                                                        effect="dark"
                                                        placement="right"
                                                    >
                                                        <div slot="content">您可以在这里选择适合您项目的分类</div>
                                                        <el-link
                                                            :underline="false"
                                                            icon="jk-icon-yiwen ml-5"
                                                            class="grey"
                                                        ></el-link>
                                                    </el-tooltip>
                                                </el-form-item>
                                            </el-col>

                                            <el-col :span="24">
                                                <!-- 项目类型:1，竞价项目 2，比选项目，3，日薪项目  -->
                                                <div
                                                    class="yl-jjxm-box"
                                                    v-if="preview"
                                                >
                                                    <div
                                                        class="prev-tips"
                                                        v-if="formdDatas.task_type==2"
                                                    >
                                                        <div class="prev-label">采购方式</div>
                                                        {{ buyType + '，项目酬金：'+ formdDatas.remuneration }}元
                                                    </div>
                                                    <div
                                                        class="prev-tips"
                                                        v-if="formdDatas.task_type==1"
                                                    >
                                                        <div class="prev-label">采购方式</div>
                                                        {{ buyType +'，项目酬金：'+ helpDatas.remuneration_min + '-'+ helpDatas.remuneration_max }}元
                                                    </div>
                                                    <div
                                                        class="prev-tips"
                                                        v-if="formdDatas.task_type==3"
                                                    >
                                                        <div class="prev-label">采购方式</div>
                                                        {{ buyType+'，项目酬金：每天：'+ formdDatas.remuneration+'元（共）'+ helpDatas.task_day+'天' }}
                                                    </div>
                                                </div>

                                                <el-form-item
                                                    v-else
                                                    label="采购方式"
                                                    class="need-filed"
                                                    prop="task_type"
                                                >
                                                    <el-select
                                                        class="width-100"
                                                        v-model="formdDatas.task_type"
                                                        placeholder="请选择采购方式"
                                                        @change="changeTaskType"
                                                    >
                                                        <el-option
                                                            v-for=" (item,index) in taskTypeOptions"
                                                            :label="item.label"
                                                            :value="item.value"
                                                            :key="index"
                                                        ></el-option>
                                                    </el-select>

                                                    <el-tooltip
                                                        class="item"
                                                        effect="light"
                                                        placement="top"
                                                    >
                                                        <div slot="content">
                                                            <img
                                                                :src="$img.task_desc"
                                                                style="width:700px"
                                                            />
                                                        </div>
                                                        <el-link
                                                            :underline="false"
                                                            icon="jk-icon-yiwen ml-5"
                                                            class="grey"
                                                        ></el-link>
                                                    </el-tooltip>
                                                </el-form-item>
                                            </el-col>

                                            <el-col
                                                :span="24"
                                                v-if="!preview"
                                                style="margin-top: -11px!important;"
                                            >
                                                <el-form-item
                                                    label
                                                    prop="remuneration"
                                                >
                                                    <div
                                                        class="right-min-form-box"
                                                        v-if="formdDatas.task_type != ''"
                                                    >
                                                        <header class="right-min-hd">
                                                            <span class="tit">
                                                                请填写项目酬金（注意：
                                                                <i v-text="remuneration_desc"></i>）
                                                            </span>
                                                        </header>

                                                        <div class="min-form-bd">
                                                            <div
                                                                class="public-jjxm-box"
                                                                v-if="!preview"
                                                            >
                                                                <el-form-item
                                                                    class="double-input"
                                                                    v-if="formdDatas.task_type==2"
                                                                    prop="remuneration"
                                                                    name="remuneration"
                                                                >
                                                                    <el-input
                                                                        style="width: 100%;"
                                                                        v-model="formdDatas.remuneration"
                                                                        clearable
                                                                        placeholder="请输入项目酬金(不能低于100元)"
                                                                        type="number"
                                                                        ref="task_remuneration"
                                                                        :autofocus="true"
                                                                        @change="changeMoney"
                                                                    ></el-input>
                                                                </el-form-item>

                                                                <el-form-item
                                                                    class="double-input"
                                                                    v-if="formdDatas.task_type==1"
                                                                    prop="remuneration"
                                                                    name="remuneration"
                                                                    style="font-size: 0"
                                                                >
                                                                    <el-input
                                                                        v-model="helpDatas.remuneration_min"
                                                                        clearable
                                                                        placeholder="请输入酬金下限(不能低于100元)"
                                                                        type="number"
                                                                        :autofocus="true"
                                                                        @change="changeMoney"
                                                                    ></el-input>

                                                                    <el-input
                                                                        v-model="helpDatas.remuneration_max"
                                                                        clearable
                                                                        placeholder="请输入酬金上限(不能低于100元且不能低于酬金下限)"
                                                                        type="number"
                                                                        :autofocus="true"
                                                                        ref="remuneration_max"
                                                                        @change="changeMoney"
                                                                    ></el-input>
                                                                </el-form-item>

                                                                <el-form-item
                                                                    v-if="formdDatas.task_type==3"
                                                                    class="double-input"
                                                                    prop="remuneration"
                                                                    name="remuneration"
                                                                >
                                                                    <el-input
                                                                        v-model="formdDatas.remuneration"
                                                                        clearable
                                                                        placeholder="请输入每天酬金"
                                                                        type="number"
                                                                        :autofocus="true"
                                                                        ref="task_day_money"
                                                                        @change="changeMoney"
                                                                    ></el-input>
                                                                    <el-input
                                                                        v-model="helpDatas.task_day"
                                                                        clearable
                                                                        placeholder="雇主指定项目所用总时间,总用时不能低于1天。"
                                                                        type="number"
                                                                        :autofocus="true"
                                                                        ref="task_day"
                                                                        @change="changeMoney"
                                                                    ></el-input>
                                                                </el-form-item>

                                                                <p
                                                                    v-if="formdDatas.task_type==3"
                                                                    style="margin-top: 30px;text-align: right;"
                                                                >
                                                                    酬金总额:
                                                                    <span>{{allMoney}}</span>元
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </el-form-item>
                                            </el-col>

                                            <el-col :span="24">
                                                <div
                                                    class="prev-tips"
                                                    v-if="preview"
                                                >
                                                    <div class="prev-label">报名截止时间</div>
                                                    {{ formdDatas.end_time || '暂未填写' }}
                                                </div>
                                                <el-form-item
                                                    v-else
                                                    label="报名截止时间"
                                                    class="need-filed double-input timersel"
                                                    style="margin: 0 0 15px 0;"
                                                    prop="end_time"
                                                    name="end_time"
                                                >
                                                    <!--  <i class="is-need">*</i> -->
                                                    <el-date-picker
                                                        align="center"
                                                        class="end-date-picker date_picker"
                                                        v-model="formdDatas.end_time"
                                                        type="date"
                                                        style="width: 100%;margin-left:0px"
                                                        placeholder="选择报名截止时间（报名时间最长为14天，报名截止后的7天内，如果您未能选出合适的工程极客，则此项目会自动结束）"
                                                        value-format="yyyy-MM-dd HH:mm:ss"
                                                        default-time="23:59:59"
                                                        editable
                                                        :picker-options="endDatePicker"
                                                        @change="endTimeChange"
                                                    ></el-date-picker>
                                                </el-form-item>
                                            </el-col>
                                            <el-col
                                                :span="24"
                                                v-if="formdDatas.task_type==3"
                                            >
                                                <div
                                                    class="prev-tips"
                                                    v-if="preview"
                                                >
                                                    <div class="prev-label">项目计划开始时间和完成时间</div>
                                                    {{ formdDatas.work_time?(formdDatas.work_time[0]+' 至 '+formdDatas.work_time[1]) : '暂未填写' }}
                                                </div>
                                                <el-form-item
                                                    v-else
                                                    label="项目计划开始时间"
                                                    class="need-filed double-input timersel"
                                                    style="margin: 0 0 15px 0;"
                                                    prop="end_time"
                                                    name="end_time"
                                                >
                                                    <!--  <i class="is-need">*</i> -->
                                                    <el-date-picker
                                                        align="center"
                                                        class="end-date-picker date_picker"
                                                        v-model="taskDayStart"
                                                        type="date"
                                                        style="width: 100%;margin-left:0px"
                                                        placeholder="选择项目计划开始时间（项目计划截止时间会自动计算）"
                                                        value-format="yyyy-MM-dd"
                                                        editable
                                                        :picker-options="pickerOptions"
                                                        @change="endTaskDay"
                                                    ></el-date-picker>
                                                </el-form-item>
                                            </el-col>
                                            <el-col
                                                :span="24"
                                                v-else
                                            >
                                                <div
                                                    class="prev-tips"
                                                    v-if="preview"
                                                >
                                                    <div class="prev-label">项目计划开始时间和完成时间</div>
                                                    {{ formdDatas.work_time?(formdDatas.work_time[0]+' 至 '+formdDatas.work_time[1]) : '暂未填写' }}
                                                </div>
                                                <el-form-item
                                                    v-else
                                                    label="项目计划开始时间和完成时间"
                                                    class="need-filed"
                                                    prop="work_time"
                                                    name="work_time"
                                                >
                                                    <el-date-picker
                                                        class="width-100 task_time_box"
                                                        v-model="formdDatas.work_time"
                                                        type="daterange"
                                                        range-separator="至"
                                                        start-placeholder="开始日期"
                                                        end-placeholder="结束日期"
                                                        format="yyyy 年 MM 月 dd 日"
                                                        value-format="yyyy-MM-dd"
                                                        :picker-options="pickerOptions"
                                                        ref="work_time"
                                                        @change="changeNode"
                                                    ></el-date-picker>
                                                </el-form-item>
                                            </el-col>

                                            <el-col :span="24">
                                                <div
                                                    class="prev-tips"
                                                    v-if="preview"
                                                >
                                                    <div class="prev-label">工作地点要求</div>
                                                    {{ formdDatas.work_area || '暂未填写' }}
                                                </div>
                                                <el-form-item
                                                    v-else
                                                    label="工作地点要求"
                                                >
                                                    <el-input
                                                        class="width-100"
                                                        v-model="formdDatas.work_area"
                                                        type="input"
                                                        clearable
                                                        maxlength="50"
                                                        placeholder="请填写工作地点的要求"
                                                        :autofocus="true"
                                                        @change="changeNode"
                                                    ></el-input>
                                                </el-form-item>
                                            </el-col>

                                            <el-col :span="24">
                                                <div
                                                    class="prev-tips"
                                                    v-if="preview"
                                                >
                                                    <div class="prev-label">项目成果要求</div>
                                                    {{ formdDatas.introduce || '暂未填写' }}
                                                </div>
                                                <el-form-item
                                                    v-else
                                                    label="项目成果要求"
                                                    class="need-filed"
                                                    prop="introduce"
                                                    name="introduce"
                                                >
                                                    <el-input
                                                        class="introduce-textarea"
                                                        type="textarea"
                                                        placeholder="请输入项目成果要求（1000字以内）"
                                                        v-model="formdDatas.introduce"
                                                        maxlength="1000"
                                                        @change="changeNode"
                                                    ></el-input>
                                                    <span
                                                        style="color:#587ddc;cursor:pointer"
                                                        @click="introduceTemplate"
                                                    >查看示例</span>
                                                </el-form-item>
                                            </el-col>

                                            <el-col :span="24">
                                                <div
                                                    class="prev-tips"
                                                    v-if="preview"
                                                >
                                                    <div class="prev-label">项目详情</div>
                                                    {{ formdDatas.explain || '暂未填写' }}
                                                </div>
                                                <el-form-item
                                                    v-else
                                                    label="项目详情"
                                                    class="need-filed"
                                                    prop="explain"
                                                    name="explain"
                                                >
                                                    <el-input
                                                        class="introduce-textarea"
                                                        type="textarea"
                                                        placeholder="请填写项目详情（2000字以内）"
                                                        v-model="formdDatas.explain"
                                                        maxlength="2000"
                                                        @change="changeNode"
                                                    ></el-input>
                                                    <span
                                                        style="color:#587ddc;cursor:pointer"
                                                        @click="contentTemplate"
                                                    >查看示例</span>
                                                </el-form-item>
                                            </el-col>

                                            <el-col :span="24">
                                                <div class="prev-tips">
                                                    <div class="prev-label">附件</div>
                                                </div>
                                                <upload-annex
                                                    v-model="formdDatas.annex"
                                                    @success="local_save"
                                                    @del="local_save"
                                                ></upload-annex>
                                                <div class="el-upload__tip">项目附件-您可以上传用于补充说明本项目的文件附件，可以是图片，文本或压缩包</div>
                                            </el-col>
                                        </div>
                                    </div>
                                </div>

                                <!--第二步 项目要求 -->
                                <div
                                    class="steps"
                                    v-if="activeStepNum !=3"
                                >
                                    <div class="info-inner mt-0">
                                        <div
                                            class="info-card-panner"
                                            style="padding-bottom: 0;"
                                        >
                                            <header class="panner-hd">
                                                <h2 class="tit">报名要求</h2>
                                            </header>
                                            <div
                                                class="panner-bd user-introduce"
                                                style="border-bottom:none"
                                            >
                                                <el-col :span="24">
                                                    <div
                                                        class="prev-tips"
                                                        v-if="preview"
                                                    >
                                                        <div class="prev-label">报名身份要求</div>
                                                        {{ formdDatas.geeks_type| getLabel(userTypeData) }}
                                                    </div>
                                                    <el-form-item
                                                        v-else
                                                        label="报名身份要求"
                                                        class="need-filed"
                                                        prop="geeks_type"
                                                        name="geeks_type"
                                                    >
                                                        <!-- <i class="is-need">*</i> -->
                                                        <el-select
                                                            class="width-100"
                                                            v-model="formdDatas.geeks_type"
                                                            placeholder="请选择极客身份类型要求"
                                                            @change="changeNode"
                                                        >
                                                            <el-option
                                                                v-for="item in userTypeData"
                                                                :key="item.value"
                                                                :label="item.label"
                                                                :value="item.value"
                                                            ></el-option>
                                                        </el-select>
                                                    </el-form-item>
                                                </el-col>
                                                <el-col :span="24">
                                                    <div
                                                        class="prev-tips"
                                                        v-if="preview"
                                                    >
                                                        <div class="prev-label">资质（资格）要求</div>
                                                        {{ formdDatas.geeks_credentials || '暂未填写' }}
                                                    </div>
                                                    <el-form-item
                                                        v-else
                                                        label="资质（资格）要求"
                                                        class="need-filed"
                                                        name="geeks_credentials"
                                                    >
                                                        <!-- <i class="is-need">*</i> -->
                                                        <el-input
                                                            class="introduce-textarea textarea-limit"
                                                            type="textarea"
                                                            placeholder="请输入您对工程极客的资质（资格）要求（非必填）"
                                                            v-model="formdDatas.geeks_credentials"
                                                            maxlength="500"
                                                            @change="changeNode"
                                                        ></el-input>
                                                        <span
                                                            style="color:#587ddc;cursor:pointer"
                                                            @click="geekzzTemplate"
                                                        >查看示例</span>
                                                    </el-form-item>
                                                </el-col>

                                                <el-col :span="24">
                                                    <el-form-item
                                                        class="regist-addr"
                                                        name="area_limit"
                                                        label="极客注册地要求"
                                                    >
                                                        <!--options里数据弄为地区 弄为两级就是两级联动的-->
                                                        <el-cascader
                                                            ref="geekAddSel"
                                                            class="width-100"
                                                            v-model="formdDatas.area_limit"
                                                            :options="areaList"
                                                            clearable
                                                            placeholder="不限制"
                                                            @change="handleChange"
                                                        ></el-cascader>
                                                        <el-tooltip
                                                            class="item"
                                                            effect="dark"
                                                            placement="right"
                                                        >
                                                            <div slot="content">注：您可以在这里设置的地区要求，默认为不限制。设置限制后，仅工作地点符合要求的工程极客可以报名此项目。（最多可设置4个限制地区）</div>
                                                            <el-link
                                                                :underline="false"
                                                                icon="jk-icon-yiwen ml-5"
                                                                class="grey"
                                                            ></el-link>
                                                        </el-tooltip>
                                                    </el-form-item>
                                                </el-col>

                                                <!-- <el-col :span="24">
                                                    <div class="prev-tips"
                                                         v-if="preview">
                                                        <div class="prev-label">工程极客履约保证金要求</div>
                                                        <span v-if="formdDatas.is_bond != '1'">不需要</span>
                                                        <span v-else>{{ formdDatas.bond || '暂未填写' }}</span>
                                                    </div>
                                                    <el-form-item v-else
                                                                  label="工程极客履约保证金要求"
                                                                  class="line-height-1-5 double-input"
                                                                  prop="is_bond"
                                                                  name="is_bond"
                                                                  style="margin: 0">
                                                        <el-select v-model="formdDatas.is_bond"
                                                                   placeholder="工程极客履约保证金要求"
                                                                   style="float: left; width: 50%;"
                                                                   class="bzj"
                                                                   @change="changeNode">
                                                            <el-option class="width-100"
                                                                       v-for="item1 in bondList"
                                                                       :key="item1.value"
                                                                       :label="item1.label"
                                                                       :value="item1.value"></el-option>
                                                        </el-select>

                                                        <el-input class="bzj"
                                                                  :disabled="formdDatas.is_bond != '1'"
                                                                  v-model="formdDatas.bond"
                                                                  clearable
                                                                  placeholder="0 元"
                                                                  type="number"
                                                                  :autofocus="true"
                                                                  @change="changeNode"></el-input>
                                                    </el-form-item>
                                                </el-col>-->
                                                <el-col :span="24">
                                                    <div
                                                        class="prev-tips"
                                                        v-if="preview"
                                                    >
                                                        <div class="prev-label">工程极客是否需要上传附件</div>
                                                        <span>{{ formdDatas.demand_annex|getLabel(demand_annex)}} {{ "："+ formdDatas.demand_explain }}</span>
                                                    </div>
                                                    <div v-else>
                                                        <el-form-item
                                                            label="工程极客是否需要上传附件"
                                                            class="line-height-1-5 need-filed pb-upfile"
                                                            prop="demand_annex"
                                                            name="demand_annex"
                                                        >
                                                            <!--  <i class="is-need">*</i> -->
                                                            <el-select
                                                                class="width-100"
                                                                v-model="formdDatas.demand_annex"
                                                                placeholder="请选择工程极客是否需要上传附件"
                                                                @change="changeNode"
                                                            >
                                                                <el-option
                                                                    v-for="item in demand_annex"
                                                                    :key="item.value"
                                                                    :label="item.label"
                                                                    :value="item.value"
                                                                ></el-option>
                                                            </el-select>
                                                        </el-form-item>
                                                        <el-form-item
                                                            v-if="formdDatas.demand_annex == '1'"
                                                            prop="demand_explain"
                                                            name="demand_explain"
                                                            style="margin-top: -20px;"
                                                        >
                                                            <el-input
                                                                class="introduce-textarea"
                                                                type="textarea"
                                                                placeholder="请输入附件说明"
                                                                v-model="formdDatas.demand_explain"
                                                                @change="changeNode"
                                                                maxlength="50"
                                                            ></el-input>
                                                        </el-form-item>
                                                    </div>
                                                </el-col>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <!-- 第三步 联系方式  -->
                                <div
                                    class="steps"
                                    v-if="activeStepNum !=3"
                                >
                                    <div
                                        class="info-inner"
                                        style="margin-top: 0;"
                                    >
                                        <div
                                            class="info-card-panner"
                                            style="margin-top: 0;padding-top: 0;"
                                        >
                                            <header class="panner-hd">
                                                <h2 class="tit">联系方式</h2>
                                            </header>
                                            <div
                                                class="panner-bd user-introduce"
                                                style="padding-top:0;border-bottom: none;"
                                            >
                                                <el-col :span="24">
                                                    <div
                                                        class="prev-tips"
                                                        v-if="preview"
                                                    >
                                                        <div class="prev-label">联系人姓名</div>
                                                        <span>{{ formdDatas.contact || '暂未填写' }}</span>
                                                    </div>
                                                    <el-form-item
                                                        v-else
                                                        label="联系人姓名"
                                                        prop="contact"
                                                        name="contact"
                                                        class="need-filed"
                                                    >
                                                        <!-- <i class="is-need">*</i> -->
                                                        <!-- 注，验证时，prop和name 的值必须相等 -->
                                                        <el-input
                                                            v-model="formdDatas.contact"
                                                            class="width-100"
                                                            clearable
                                                            placeholder="请输入联系人姓名"
                                                            :autofocus="true"
                                                            @change="changeNode"
                                                        ></el-input>
                                                    </el-form-item>
                                                </el-col>
                                                <el-col :span="24">
                                                    <div
                                                        class="prev-tips"
                                                        v-if="preview"
                                                    >
                                                        <div class="prev-label">联系人手机号码</div>
                                                        <span>{{ formdDatas.phone || '暂未填写' }}</span>
                                                    </div>
                                                    <el-form-item
                                                        v-else
                                                        label="联系人手机号码"
                                                        prop="phone"
                                                        name="phone"
                                                        :rules="[{required: true, message: '请填写您的手机号码', trigger: ['change', 'blur']},{validator: this.validata.mobile(), trigger: ['change', 'blur']}]"
                                                    >
                                                        <!-- <i class="is-need">*</i> -->
                                                        <!-- 注，验证时，prop和name 的值必须相等 -->
                                                        <el-input
                                                            type="number"
                                                            v-model="formdDatas.phone"
                                                            class="width-100 contact-mobile"
                                                            clearable
                                                            placeholder="请输入联系人手机号码"
                                                            :autofocus="true"
                                                            @change="changeNode"
                                                        ></el-input>
                                                    </el-form-item>
                                                </el-col>

                                                <el-col>
                                                    <span style="font-size:14px;color:#999;">您在这里填写的联系人和联系方式仅供询龙网工作人员方便联系您使用，并不会发布在项目信息中，询龙网将严格保护您的隐私信息。平台的所有提示短信仍将发送到您的注册手机号。</span>
                                                </el-col>

                                                <el-col
                                                    :span="24"
                                                    v-if="!preview"
                                                >
                                                    <div
                                                        class="cont-btn cont_btn"
                                                        style="margin-top:20px;"
                                                    >
                                                        <el-button @click="save(3)">预览</el-button>
                                                        <el-button @click="save(1)">保存</el-button>
                                                        <el-button
                                                            @click="save(2)"
                                                            class="active"
                                                            type="primary"
                                                        >发布</el-button>
                                                    </div>
                                                </el-col>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div
                                    class="steps"
                                    v-if="activeStepNum ==3 && activeStepNum !=2"
                                    style="margin-top: 20px;"
                                >
                                    <div class="info-inner">
                                        <div class="info-card-panner">
                                            <div
                                                class="panner-bd"
                                                style="border: none;"
                                            >
                                                <div class="tips-inner">
                                                    <span class="icon-wrap">
                                                        <i class="jk-icon-gou icon"></i>
                                                    </span>
                                                    <p class="tips">提交成功</p>
                                                    <p class="desc">您所填写的项目已经提交，此项目在审核完成后即可自动发布在询龙网上，请您耐心等待。</p>
                                                    <router-link to="/user/index">
                                                        <el-button>返回</el-button>
                                                    </router-link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </el-row>
                        </el-form>
                    </div>
                </div>

                <!-- 项目发布进度 -->
                <div
                    :class="isFixedTopClass"
                    v-if="activeStepNum !=3"
                >
                    <div class="aslide-panel">
                        <header class="pross-hd">
                            <h4 class="pross-tit">项目完善度</h4>
                            <el-progress
                                class="pross-circle"
                                :stroke-width="10"
                                :type="progressType"
                                :percentage="point"
                                color="#5891dc"
                            ></el-progress>
                            <p class="pross-desc">完善项目内容，有利于提升极客回应率以及有利于项目成功</p>
                        </header>
                        <div class="pross-bd">
                            <ul>
                                <li :class="nodeClass.do_task_name == true?'active':''">
                                    <span class="fl-r">5%</span>
                                    <i class="gou active"></i>完善项目名称
                                </li>
                                <li :class="nodeClass.do_speciality_type == true?'active':''">
                                    <span class="fl-r">5%</span>
                                    <i class="gou"></i>完善服务类型
                                </li>
                                <li :class="nodeClass.do_task_type == true?'active':''">
                                    <span class="fl-r">5%</span>
                                    <i class="gou"></i>完善采购方式
                                </li>
                                <li :class="nodeClass.do_task_remuneration == true?'active':''">
                                    <span class="fl-r">10%</span>
                                    <i class="gou"></i>完善项目酬金
                                </li>
                                <li :class="nodeClass.do_end_time == true?'active':''">
                                    <span class="fl-r">5%</span>
                                    <i class="gou"></i>设置项目报名截止时间
                                </li>
                                <li :class="nodeClass.do_work_time == true?'active':''">
                                    <span class="fl-r">5%</span>
                                    <i class="gou"></i>设置项目计划开始时间和结束时间
                                </li>
                                <li :class="nodeClass.do_work_area == true?'active':''">
                                    <span class="fl-r">5%</span>
                                    <i class="gou"></i>完善工作地点要求
                                </li>
                                <li :class="nodeClass.do_introduce == true?'active':''">
                                    <span class="fl-r">10%</span>
                                    <i class="gou"></i>项目成果要求
                                </li>
                                <li :class="nodeClass.do_explain == true?'active':''">
                                    <span class="fl-r">10%</span>
                                    <i class="gou"></i>完善项目详情
                                </li>
                                <li :class="nodeClass.do_annex == true?'active':''">
                                    <span class="fl-r">5%</span>
                                    <i class="gou"></i>完善项目附件
                                </li>
                                <li :class="nodeClass.do_geeks_type == true?'active':''">
                                    <span class="fl-r">5%</span>
                                    <i class="gou"></i>报名身份要求
                                </li>
                                <li :class="nodeClass.do_geeks_credentials == true?'active':''">
                                    <span class="fl-r">5%</span>
                                    <i class="gou"></i>完善资质（资格）要求
                                </li>
                                <li :class="nodeClass.do_area_limit == true?'active':''">
                                    <span class="fl-r">5%</span>
                                    <i class="gou"></i>设置极客注册地要求
                                </li>
                                <li :class="nodeClass.do_is_bond == true?'active':''">
                                    <span class="fl-r">5%</span>
                                    <i class="gou"></i>设置工程极客履约保证金要求
                                </li>
                                <li :class="nodeClass.do_demand_annex == true?'active':''">
                                    <span class="fl-r">5%</span>
                                    <i class="gou"></i>设置是否需要上传附件
                                </li>
                                <li :class="nodeClass.do_contact == true?'active':''">
                                    <span class="fl-r">10%</span>
                                    <i class="gou"></i>完善联系人
                                </li>
                            </ul>
                        </div>
                        <el-col
                            :span="24"
                            v-if="preview == true"
                        >
                            <div class="cont-btn">
                                <el-button @click="preview=!preview">返回编辑</el-button>
                                <el-button
                                    @click="save(2)"
                                    class="active"
                                    type="primary"
                                >立即发布</el-button>
                            </div>
                        </el-col>
                    </div>
                </div>
            </div>
        </section>

        <div class="publish_task_emptyArea"></div>
        <el-dialog
            :title="titleExample"
            :visible.sync="showExample"
            width="40%"
            center
        >
            <div
                v-html="contentExample"
                style="text-indent: 2em;letter-spacing: 1px;line-height: 26px;"
            ></div>
            <span
                slot="footer"
                class="dialog-footer"
            >
                <el-button @click="showExample = false">关 闭</el-button>
            </span>
        </el-dialog>
        <!-- 遮挡右侧进度条  -->
        <global-footer></global-footer>
    </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";
import GlobalFooter from "@/components/common/GlobalFooter.vue";
import onlyUpload from "@/components/upload/only-upload.vue";
import TopNav from "@/components/common/TopNav.vue";
import HeaderGuide from "@/components/common/HeaderGuide.vue";
import BreadCrumb from "@/components/common/BreadCrumb.vue";
import UploadAnnex from "@/components/upload/UploadAnnex.vue";
export default {
    name: "taskPublish",
    components: {
        GlobalFooter, // 公共底部
        onlyUpload, // 单图文字样式上传组件
        TopNav, // 公共顶部
        HeaderGuide, // 头部导航搜索
        BreadCrumb, // 面包屑
        UploadAnnex //上传附件组件
    },
    computed: {
        ...mapState(["userInfo"]),
        tooltipShow: function () {
            //提示框方法
            if (!this.formdDatas.task_name) {
                return true;
            } else {
                return false;
            }
        }
    },
    data() {
        return {
            preview: false, // 是否为预览模式
            progressType: "circle", //项目进度样式
            activeStepNum: 1, // 当前到哪一步,1为填写/编辑状态，2为保存成功状态，3为发布成功状态
            isReadRead: true, // 是否阅读注册条款
            allMoney: 200,
            imgLists: [],
            buyType: "", // 采购方式
            remuneration_desc: "", //薪酬提示
            taskTypeOptions: [
                // 项目类型:1，竞价项目 2，比选项目，3，日薪项目
                { value: 1, label: "竞价项目" },
                { value: 2, label: "比选项目" },
                { value: 3, label: "日薪项目" }
            ],
            serversTypeOptions: [], // 服务类型选择
            areaList: [], //区域列表
            validRulers: {
                task_name: [
                    {
                        required: true,
                        message: "请填写项目名称",
                        errormsg: "项目名称填写错误",
                        trigger: "blur"
                    }
                ],
                speciality_type: [
                    {
                        required: true,
                        message: " ",
                        trigger: "change"
                    }
                ],
                task_type: [
                    {
                        required: true,
                        message: " ",
                        trigger: "change"
                    }
                ],
                work_time: [
                    {
                        required: true,
                        message: " ",
                        trigger: "change"
                    }
                ],
                helpDatas: {
                    remuneration_min: [ // 酬金范围小数
                        {
                            required: true,
                            message: " ",
                            trigger: "change"
                        },
                        {
                            validator: function (key, value, callback) {
                                if (value) {
                                    if (parseInt(value) < 100) {
                                        //价格不能比项目最小价格低
                                        this.helpDatas.remuneration_min = 100;
                                        callback(
                                            new Error("酬金不能低于100")
                                        );
                                    }

                                    if (parseInt(value) > 999999999) {
                                        //价格不能比项目最小价格低
                                        this.helpDatas.remuneration_min = 999999998;
                                        callback(
                                            new Error("酬金不能高于999999999")
                                        );
                                    }
                                }
                                callback();
                            },
                            trigger: ["change"]
                        }
                    ],
                    remuneration_max: [ // 酬金范围大数
                        {
                            required: true,
                            message: " ",
                            trigger: "change"
                        },
                        {
                            validator: function (key, value, callback) {
                                if (value) {
                                    if (parseInt(value) < 100) {
                                        //价格不能比项目最小价格低
                                        this.helpDatas.remuneration_max = 101;
                                        callback(
                                            new Error("酬金不能低于100")
                                        );
                                    }

                                    if (parseInt(value) > 999999999) {
                                        //价格不能比项目最小价格低
                                        this.helpDatas.remuneration_max = 999999999;
                                        callback(
                                            new Error("酬金不能高于999999999")
                                        );
                                    }
                                }
                                callback();
                            },
                            trigger: ["change"]
                        }
                    ]
                },
                remuneration: [
                    {
                        required: true,
                        message: "酬金不能为空",
                        trigger: "blur"
                    },
                    {
                        validator: function (key, value, callback) {
                            if (value) {
                                if (parseInt(value) < 100) {
                                    //价格不能比项目最小价格低
                                    callback(
                                        new Error("酬金不能低于100")
                                    );
                                }

                                if (parseInt(value) > 999999999) {
                                    //价格不能比项目最小价格低
                                    callback(
                                        new Error("酬金不能高于999999999")
                                    );
                                }
                            }
                            callback();
                        },
                        trigger: ["change"]
                    }
                ],
                explain: [
                    {
                        required: true,
                        message: " ",
                        trigger: "blur"
                    }
                ],
                end_time: [
                    {
                        required: true,
                        message: " ",
                        trigger: "change"
                    }
                ],
                area_limit: [
                    {
                        required: true,
                        message: " ",
                        trigger: "blur"
                    }
                ],
                demand_annex: [
                    {
                        required: true,
                        message: " ",
                        trigger: "blur"
                    }
                ],
                geeks_type: [
                    {
                        required: true,
                        message: " ",
                        trigger: "change"
                    }
                ],
                introduce: [
                    {
                        required: true,
                        message: " ",
                        trigger: "blur"
                    }
                ],
                geeks_credentials: [
                    {
                        required: false,
                        message: " ",
                        trigger: "blur"
                    }
                ],
                contact: [
                    {
                        required: true,
                        message: " ",
                        trigger: "blur"
                    }
                ],
                phone: [
                    {
                        required: true,
                        message: " ",
                        trigger: "blur"
                    }
                ]
            },
            demand_annex: [
                //极客是否需要上传附件
                {
                    value: "1",
                    label: "需要"
                },
                {
                    value: "0",
                    label: "不需要"
                }
            ],
            bondList: [
                {
                    value: "1",
                    label: "需要"
                },
                {
                    value: "0",
                    label: "不需要"
                }
            ],
            endDatePicker: this.processDate(),
            helpDatas: {
                remuneration_min: 0, // 酬金范围小数
                remuneration_max: 0, // 酬金范围大数
                task_day: 0
            },
            formdDatas: {
                tk: "", // 项目id 有项目id为项目id  没有则为0
                sn: "",
                task_name: "", // 项目名称
                speciality_type: "", // 服务类型
                task_type: "", // 项目类型选择结果 项目类型:1，竞价项目 2，比选项目，3，为日薪项目
                certificateImgName: "", //证件
                remuneration: 0, // 酬金 第一步
                explain: "", // 项目说明 第一步
                introduce: "",
                annex: [], // 项目附件 附件1;附件2;附件3 第一步
                end_time: "", // 报名截止时间 第二步
                area_limit: [], // 地点限制 0 不限  多地址格式地址id,地址id1,地址id2 第二步
                geeks_type: "0", // 极客身份限制 0 不限 身份id1,身份id2,身份id3 第二步
                geeks_credentials: "", // 极客资质要求说明 第二步
                demand_annex: "0", // 是否需要上传附件 第二步
                demand_explain: "", // 上传附件说明 第二步
                damages: "", // 是否需要对方托管违约金 0 否 1 是 第二步
                contact: "", // 联系人姓名
                phone: "", // 联系电话
                status: "", //项目状态（-1.审核失败；1.草稿箱；2.待审核（=发布）；3.报名中（=审核通过）；4.评选中；5.待签署；6.待托管；7.工作中；（3-7=进行中）8.已完成；9.调解中；10.已结束）
                work_time: "", // 计划工期
                bond: 0,
                is_bond: "0",
                work_area: ""
            },
            userTypeData: [
                {
                    value: "0",
                    label: "不限"
                },
                {
                    value: "1",
                    label: "个人工程极客"
                },
                {
                    value: "2",
                    label: "工作室工程极客"
                },
                {
                    value: "3",
                    label: "单位工程极客"
                }
            ],
            pickerOptions: this.pickerOptionsFun(),
            point: 0, //项目完成评分 满分一百
            nodeClass: {
                do_task_name: false, // 设定项目名称
                do_speciality_type: false, // 设定服务类型
                do_task_type: false, // 设定项目分类
                do_task_remuneration: false, // 设定项目酬金
                do_explain: false, // 完善项目说明
                do_annex: false, // 完善项目附件
                do_end_time: false, // 设置项目报名截止时间
                do_area_limit: false, // 设置地区限制
                do_work_area: false, // 完善工作地点要求
                do_geeks_type: false, // 限制极客身份类型
                do_introduce: false, // 完善成果要求
                do_geeks_credentials: false, // 完善资质要求
                do_work_time: false, // 设置预计工期
                do_demand_annex: false, // 设置是否需要上传附件
                do_is_bond: false, // 设置履约保证金要求
                do_contact: false // 完善联系人
            },
            isFixedTopClass: "aslide-box", //滚动页面右侧栏顶部固定
            loading: false,
            showExample: false,
            titleExample: '',
            contentExample: '',
            taskDayStart: ''
        };
    },

    methods: {
        endTimeChange() {
            let _this = this;
            _this.formdDatas.work_time = "";
            _this.changeNode();
        },
        pickerOptionsFun() {
            let _this = this;
            return {
                disabledDate(time) {
                    if (_this.formdDatas.end_time) {
                        return time.getTime() < (new Date(_this.formdDatas.end_time).getTime() + 24 * 3600 * 1000);
                    } else {
                        return time.getTime() < Date.now();
                    }
                }
            }
        },
        //监听页面滚动
        winScroll(scrollTop) {
            if (scrollTop > 37) {
                this.isFixedTopClass = "aslide-box  aslide-box-fixed";
                this.progressType = "line";
            } else {
                this.progressType = "circle";
                this.isFixedTopClass = "aslide-box";
            }
        },

        processDate() {
            return {
                disabledDate(time) {
                    if (time.getTime() > Date.now() + 14 * 86400 * 1000) {
                        return true;
                    }

                    if (time.getTime() < Date.now()) {
                        return true;
                    }
                }
            };
        },

        //酬金输入判断
        changeMoney() {
            if (this.formdDatas.task_type == 1) {
                if (parseInt(this.helpDatas.remuneration_max) < 100) {
                    //价格不能比项目最小价格低
                    this.helpDatas.remuneration_max = 101;
                    this.$message.error("项目金额最大值不能低于101,自动设定最小值");
                }

                if (parseInt(this.helpDatas.remuneration_max) > 999999999) {
                    //价格不能比项目最小价格低
                    this.helpDatas.remuneration_max = 999999999;
                    this.$message.error("项目金额最大值不能高于999999999,自动设定最大值");
                }
                if (parseInt(this.helpDatas.remuneration_min) < 100) {
                    //价格不能比项目最小价格低
                    this.helpDatas.remuneration_min = 100;
                    this.$message.error("项目金额最小值不能低于100,自动设定最小值");
                }

                if (parseInt(this.helpDatas.remuneration_min) > 999999999) {
                    //价格不能比项目最小价格低
                    this.helpDatas.remuneration_min = 999999998;
                    this.$message.error("项目金额最小值不能高于999999998,自动设定最大值");
                }
                if (
                    parseInt(this.helpDatas.remuneration_min) >
                    parseInt(this.helpDatas.remuneration_max)
                ) {
                    this.$refs.remuneration_max.focus()
                    this.$message.error("项目酬金上限不能低于下限");
                    return false;
                }
                //竞价项目
                if (
                    this.helpDatas.remuneration_min == "" ||
                    this.helpDatas.remuneration_max == ""
                ) {
                    this.helpDatas.remuneration = "";
                } else {
                    this.formdDatas.remuneration =
                        this.helpDatas.remuneration_min +
                        "-" +
                        this.helpDatas.remuneration_max;
                }

            } else if (this.formdDatas.task_type == 3) {
                //日薪
                if (parseInt(this.formdDatas.remuneration * this.helpDatas.task_day) > 999999999) {
                    //价格不能比项目最小价格低
                    this.$refs.task_day_money.focus()
                    this.$message.error("项目金额最小值不能高于999999999");
                }
                if (parseInt(this.formdDatas.remuneration) < 200) {
                    //价格不能比项目最小价格低
                    this.formdDatas.remuneration = 200;
                    this.$refs.task_day_money.focus()
                    this.$message.error("每天金额最小值不能低于200,自动设定200");
                }
                if (parseInt(this.helpDatas.task_day) < 1) {
                    //价格不能比项目最小价格低
                    this.helpDatas.task_day = 1;
                    this.$refs.task_day.focus()
                    this.$message.error("项目工期不能低于1天");
                }
                if (parseInt(this.helpDatas.task_day) > 365) {
                    //价格不能比项目最小价格低
                    this.helpDatas.task_day = 365;
                    this.$refs.task_day.focus()
                    this.$message.error("项目工期不能高于365天");
                }
                this.formdDatas.task_day = this.helpDatas.task_day; //项目天数（日薪项目必填项）
                this.formdDatas.work_time = '';
                this.allMoney = (
                    this.formdDatas.remuneration * this.helpDatas.task_day
                ).toLocaleString(); //酬金总额计算
            } else {
                if (parseInt(this.formdDatas.remuneration) > 999999999) {
                    //价格不能比项目最小价格低
                    this.formdDatas.remuneration = 999999999;
                    this.$refs.task_remuneration.focus()
                    this.$message.error("项目金额最大值不能高于999999999,自动设定最大值");
                }
                if (parseInt(this.formdDatas.remuneration) < 100) {
                    //价格不能比项目最小价格低
                    this.formdDatas.remuneration = 100;
                    this.$refs.task_remuneration.focus()
                    this.$message.error("项目金额最小值不能低于100,自动设定最小值");
                }
            }
            this.changeNode();
        },

        changeTaskType(v) {
            var _this = this;
            for (var i = 0; i < this.taskTypeOptions.length; i++) {
                // 设置采购方式显示
                if (this.taskTypeOptions[i].value == v) {
                    _this.buyType = _this.taskTypeOptions[i].label;
                }
            }
            this.remuneration_desc = "酬金不能低于100元";
            if (this.formdDatas.task_type == 1) {
                this.helpDatas.remuneration_min = "";
                this.helpDatas.remuneration_max = "";
                this.remuneration_desc =
                    "设置项目酬金范围，极客报名时会在此范围内进行报价。";
            }
            if (parseInt(this.formdDatas.task_type) == 2) {
                this.formdDatas.remuneration = "";
                this.remuneration_desc =
                    "设置项目酬金，工程极客报名时无需报价。酬金不能低于100元。";
            }
            if (this.formdDatas.task_type == 3) {
                this.formdDatas.remuneration = "";
                this.helpDatas.task_day = "";
                this.remuneration_desc =
                    "雇主设定每天的酬金，工程极客报名时无需报价，每日酬金不能低于200元。";
            }
            this.changeNode();
        },

        //打开预览界面
        openView(title, content) {
            this.$alert(content, {
                title: title,
                dangerouslyUseHTMLString: true
            });
        },

        endTaskDay() {
            let end = new Date(new Date(this.taskDayStart).getTime() + (this.helpDatas.task_day - 1) * 24 * 3600 * 1000);
            let m = (end.getMonth() + 1 < 10 ? '0' + (end.getMonth() + 1) : end.getMonth() + 1) + '-';
            let day = (end.getDate() < 10 ? '0' + (end.getDate()) : end.getDate());
            let work_time = [this.taskDayStart, end.getFullYear() + '-' + m + day];
            this.formdDatas.work_time = work_time;
        },

        //添加项目
        save(status) {
            var _this = this;
            if (_this.formdDatas.task_type == 1 && parseInt(_this.helpDatas.remuneration_max) < parseInt(_this.helpDatas.remuneration_min)) {
                _this.$message.error("竞价任务最高价不能低于最低价");
                _this.$refs.remuneration_max.focus()
                return false;
            }
            if (_this.formdDatas.task_type == 3) {
                // 检查时间设定合法
                if (_this.formdDatas.work_time != '') {
                    let diff = new Date(_this.formdDatas.work_time[1]).getTime() - new Date(_this.formdDatas.work_time[0]).getTime();
                    let days = Math.floor(diff / (24 * 3600 * 1000));

                    if ((days + 1) != Number(this.helpDatas.task_day)) {
                        // this.formdDatas.work_time = '';
                        _this.$message.error("项目周期时间与计划天数不符");
                        _this.$refs.work_time.focus();
                        return false;
                    }
                }
            }
            _this.$refs["validRulers"].validate(valid => {
                if (valid) {
                    _this.formdDatas.status = status == 2 ? 2 : 1; // 项目状态（-1.审核失败；1.草稿箱；2.待审核（=发布）；3.报名中（=审核通过）；4.评选中；5.待签署；6.待托管；7.工作中；（3-7=进行中）8.已完成；9.调解中；10.已结束）
                    _this.loading = true;
                    _this.post("/task/task/add", _this.formdDatas, function (
                        res
                    ) {
                        _this.loading = false;
                        if (res.code == 200) {
                            sessionStorage.removeItem("_task_detail_"); //保存到服务器成功，清除缓存
                            _this.formdDatas.tk = res.data;
                            _this.taskDetail(res.data);
                            switch (status) {
                                case 1: //保存
                                    _this.activeStepNum = 2;
                                    _this.$message({
                                        message: "已成功保存到草稿箱",
                                        type: "success"
                                    });
                                    return;
                                    break;
                                case 2: //发布
                                    _this.activeStepNum = 3;
                                    scrollTop.scrollIntoView();
                                    return;
                                    break;
                                case 3: //预览
                                    _this.preview = true;
                                    return;
                                    break;
                                default:
                                    _this.$message.error("操作异常");
                                    return;
                                    break;
                            }
                        } else {
                            _this.$message.error(res.msg);
                            return;
                        }
                    });
                } else {
                    _this.nextSteps();
                }
            });
        },

        //缓存表单
        local_save() {
            sessionStorage.setItem(
                "_task_detail_",
                JSON.stringify(this.formdDatas)
            ); //添加缓存
        },

        nextSteps() {
            // 下一步
            if (this.formdDatas.task_name == "") {
                this.$message.error("项目名称必填");
                return false;
            }

            if (this.formdDatas.speciality_type == "") {
                this.$message.error("请选择服务类型");
                return false;
            }

            if (!this.formdDatas.task_type) {
                this.$message.error("请选择采购方式");
                return false;
            }

            if (this.formdDatas.task_type == 1) {
                if (
                    !this.helpDatas.remuneration_min ||
                    parseInt(this.helpDatas.remuneration_min) < 100
                ) {
                    this.$message.error("项目酬金下限不能低于100元");
                    return false;
                }
                if (
                    !this.helpDatas.remuneration_max ||
                    parseInt(this.helpDatas.remuneration_max) < 100
                ) {
                    this.$message.error("项目酬金上限不能低于100元");
                    return false;
                }
                if (
                    parseInt(this.helpDatas.remuneration_min) >
                    parseInt(this.helpDatas.remuneration_max)
                ) {
                    this.$message.error("项目酬金上限不能低于下限");
                    return false;
                }
            }
            if (parseInt(this.formdDatas.task_type) == 2) {
                if (this.formdDatas.remuneration < 100) {
                    this.formdDatas.remuneration = 100;
                    this.$message.error("比选项目金额不能低于100元");
                    return false;
                }
            }
            if (this.formdDatas.task_type == 3) {
                if (parseInt(this.formdDatas.remuneration) < 200) {
                    this.$message.error("项目酬金不能低于200元");
                    return false;
                }
                if (this.helpDatas.task_day < 1) {
                    this.$message.error("项目工期不能低于1天");
                    return false;
                }
            }
            if (this.formdDatas.work_time == "") {
                this.$message.error("请选择项目开始时间和完成时间");
                return false;
            }
            if (this.formdDatas.introduce == "") {
                this.$message.error("请填写项目成果要求");
                return false;
            }
            if (this.formdDatas.explain == "") {
                this.$message.error("请填写项目详情");
                return false;
            }

            if (this.formdDatas.end_time == "") {
                this.$message.error("报名截止时间不能为空");
                return false;
            }

            if (!this.formdDatas.geeks_type) {
                this.$message.error("请选报名身份要求");
                return false;
            }

            if (this.formdDatas.demand_annex == "") {
                this.$message.error("请选择工程极客是否需要上传附件");
                return false;
            }

            if (
                this.formdDatas.demand_annex == 1 &&
                this.formdDatas.demand_explain == ""
            ) {
                this.$message.error("极客上传附件说明必填");
                return false;
            }

            // if (this.formdDatas.geeks_credentials == ''){
            //     this.$message.error('极客资质说明必填');
            //     return false;
            // }

            if (this.formdDatas.contact == "") {
                this.$message.error("联系人必填");
                return false;
            }
            if (this.formdDatas.phone == "") {
                this.$message.error("联系电话必填");
                return false;
            }
            if (!/^\d{8,12}$/.test(this.formdDatas.phone)) {
                this.$message.error("手机号码有误，请重填");
                return false;
            }
        },

        //获取最新项目
        taskDetail(tk = "") {
            var _this = this;
            _this.post(
                "/task/index/detail",
                {
                    tk: tk,
                    edit: true
                },
                function (res) {
                    if (res.code == 200) {
                        if (_this.userInfo.info.uk != res.data.uk) {
                            _this.$message.error("您不能编辑该项目");
                            _this.$router.push("/user/task/list?status=0");
                            return false;
                        }
                        //项目详情
                        _this.formdDatas = res.data;
                        _this.formdDatas.work_time = [
                            res.data.work_time.split(",")[0],
                            res.data.work_time.split(",")[1]
                        ];
                        _this.formdDatas.end_time = res.data.end_time_value;
                        _this.formdDatas.area_limit = [
                            res.data.area_limit_array_value[0],
                            res.data.area_limit_array_value[1]
                        ];

                        //极客身份要求
                        switch (res.data.geeks_type) {
                            case 0:
                                _this.formdDatas.geeks_type = "0";
                                break;
                            case 1:
                                _this.formdDatas.geeks_type = "1";
                                break;
                            case 2:
                                _this.formdDatas.geeks_type = "2";
                                break;
                            case 3:
                                _this.formdDatas.geeks_type = "3";
                                break;
                            default:
                                _this.formdDatas.geeks_type = "0";
                                break;
                        }

                        //是否需要托管保证金
                        if (res.data.is_bond == 1) {
                            _this.formdDatas.is_bond = "1";
                            _this.formdDatas.bond = res.data.bond;
                        } else {
                            _this.formdDatas.is_bond = "0";
                            _this.formdDatas.bond = "";
                        }

                        //根据采购类型设置金额
                        switch (res.data.task_type) {
                            case 1: //竞价
                                _this.formdDatas.task_day = ""; //清空预计工作天数
                                _this.helpDatas.remuneration_min =
                                    res.data.remuneration_value[0]; //项目金额最小金额
                                _this.helpDatas.remuneration_max =
                                    res.data.remuneration_value[1]; //项目金额最大金额
                                _this.remuneration_desc =
                                    "雇主设定酬金区间，工程极客报名时需进行报价，酬金不能低于100元。";
                                break;
                            case 2: //比选
                                _this.formdDatas.task_day = ""; //清空预计工作天数
                                _this.formdDatas.remuneration =
                                    res.data.remuneration; //项目金额
                                _this.remuneration_desc =
                                    "雇主设置酬金，工程极客报名时无需报价。酬金不能低于100元。";
                                break;
                            case 3: //日薪
                                _this.formdDatas.task_day = res.data.task_day; //清空预计工作天数
                                _this.allMoney = res.data.remuneration;
                                _this.taskDayStart = res.data.work_time_value[0];
                                _this.formdDatas.remuneration =
                                    res.data.remuneration / res.data.task_day; //项目金额
                                _this.remuneration_desc =
                                    "雇主设定每天的酬金，工程极客报名时无需报价，每日酬金不能低于200元。";
                                _this.helpDatas.task_day = res.data.task_day;
                                break;
                            default:
                                break;
                        }

                        //是否必须上传附件
                        if (_this.formdDatas.demand_annex == 1) {
                            _this.formdDatas.demand_annex = "1"; //需要上传
                        } else {
                            _this.formdDatas.demand_annex = "0"; //不需要上传
                        }
                    }
                }
            );

            // 加载完成进度节点图
            _this.changeNode();
        },

        //获取区域列表
        getArears() {
            var _this = this;
            this.post("/system/area/area", { pid: 0 }, function (res) {
                if (res.code == 200) {
                    _this.areaList = res.data;
                }
            });
        },

        //项目详情示例
        contentTemplate() {
            // this.formdDatas.explain = "项目内容：； 项目时间：； ";
            this.contentExample = "<p style='margin-bottom: 2em;'>您可以在这里列出项目的相关详情，示例如下：</p><p>一、项目名称：**市A地块建设工程清单及拦标价编制</p><p>二、资金来源：自筹。</p><p>三、投资规模：工程造价约70000万元。</p><p>四、采购规模：120万元</p><p>五、工程概况：占地面积100亩，建筑面积20万平方米。桩基础, 框剪结构，精装修。其中地下室5万平米，二层，层高为4.5米； 5幢高层住宅，33层,层高为3.1米。</p>";
            this.titleExample = '项目详情填写示例';
            this.showExample = true;
        },

        // 项目成果示例
        introduceTemplate() {
            this.contentExample = "<p style='margin-bottom: 1em;'>您可以在这里说明您所需要获得的项目成果，即工程极客完成项目时提交给您的文档、图纸、照片或其它您所指定的工作成果。</p><p style='margin-bottom: 1em;'>项目成果要求填写示例：</p><p>采购内容：A地块建设工程清单及拦标价编制</p><p>成果要求：</p><p style='text-indent: 4em;'>1、工程量清单（纸质版、正规软件版）</p><p style='text-indent: 4em;'>2、拦标价正式稿（纸质版、正规软件版）</p><p style='text-indent: 4em;'>3、土建工程量计算（钢筋、图形软件版、EXCEL电子版）</p><p style='text-indent: 4em;'>4、安装工程量计算（软件版、EXCEL电子版）</p><p style='text-indent: 4em;'>5、符合行业质量标准和地方性要求</p><p style='text-indent: 4em;'>6、符合本地市场行情</p><p style='text-indent: 4em;'>7、在20天内提交成果</p>";
            this.titleExample = '项目成果要求填写说明和示例';
            this.showExample = true;
        },

        //资质示例
        geekzzTemplate() {
            // this.formdDatas.geeks_credentials = "极客应具备：； 具备设备：； ";
            this.contentExample = "<p style='margin-bottom: 1em;'>您可以在这里列出您希望报名的工程极客所持有的资质（资格），例如报名者是否需要持有造价工程师证书，以及是否有相关业绩等。</p><p style='margin-bottom: 1em;'>资质（资格）要求填写示例：</p><p style='font-size: large;font-weight: normal;'>一、个人服务商资格要求示例</p><p>（一）资格要求：具有一级造价工程师资格证书。</p><p>（二）业绩要求：完成建筑面积5万平米以上的房屋建筑工程清单及拦标价编制项目2个以上。</p><p style='margin-bottom: 1em;'>以上资料需在附件中提交</p><p style='font-size: large;font-weight: normal;'>二、工作室服务商资格要求示例</p><p>（一）资格要求：具有一级造价工程师资格证书。</p><p>（二）类似业绩要求：完成建筑面积20万平米以上的房屋建筑工程清单及拦标价编制项目2个以上。</p><p>（三）人员要求：具有国家注册造价工程师证书2人以上。</p><p style='margin-bottom: 1em;'>以上资料需在附件中提交</p><p style='font-size: large;font-weight: normal;'>三、单位服务商资格要求示例</p><p>（一）资质要求：具有独立承担民事责任能力的法人，提供营业执照；具备建设行政主管部门颁发的工程造价甲级资质证书。</p><p>（二）类似业绩要求：完成建筑面积20万平米以上的房屋建筑工程清单及拦标价编制项目2个以上。</p><p>（三）人员要求：具有国家注册造价工程师证书5人以上，各专业齐全。</p><p>以上资料需在附件中提交</p>";
            this.titleExample = '资质（资格）要求填写说明及示例';
            this.showExample = true;
        },

        //获取服务类型
        getTypes() {
            var _this = this;
            this.post("/task/index/service_type_list", { pid: 0 }, function (
                res
            ) {
                if (res.code == 200) {
                    _this.serversTypeOptions = res.data;
                }
            });
        },

        //设置极客注册要求
        handleChange(value) {
            let _this = this;
            if (value.length > 4) {
                this.formdDatas.area_limit.splice(
                    this.formdDatas.area_limit.length,
                    1
                );
                value.splice(value.length, 1);
                this.$message.error("最多只能选4个");
            } else {
                // 极客注册地预览数据转换
                let _tempAddArr = [];
                let _this = this;
                this.changeNode();
            }
        },

        //改变右边进度节点
        changeNode(e, type) {
            // 变更进度条
            this.formdDatas.task_name != ""
                ? (this.nodeClass.do_task_name = true)
                : (this.nodeClass.do_task_name = false); // 项目名称完成检查
            this.formdDatas.speciality_type != ""
                ? (this.nodeClass.do_speciality_type = true)
                : (this.nodeClass.do_speciality_type = false); // 项目服务类型完成检查
            this.formdDatas.task_type != ""
                ? (this.nodeClass.do_task_type = true)
                : (this.nodeClass.do_task_type = false); // 项目类型完成检查
            if (this.formdDatas.task_type == 1) {
                //竞价项目酬金完成检查
                this.helpDatas.remuneration_min >= 100 &&
                    this.helpDatas.remuneration_max > 100 &&
                    this.helpDatas.remuneration_max >
                    this.helpDatas.remuneration_min
                    ? (this.nodeClass.do_task_remuneration = true)
                    : (this.nodeClass.do_task_remuneration = false);
            }
            if (this.formdDatas.task_type == 2) {
                //比选项目酬金完成检查
                this.formdDatas.remuneration >= 100
                    ? (this.nodeClass.do_task_remuneration = true)
                    : (this.nodeClass.do_task_remuneration = false);
            }
            if (this.formdDatas.task_type == 3) {
                //日薪项目酬金完成检查
                this.formdDatas.remuneration >= 200 &&
                    this.helpDatas.task_day > 1
                    ? (this.nodeClass.do_task_remuneration = true)
                    : (this.nodeClass.do_task_remuneration = false);

                // 检查时间设定合法
                if (this.formdDatas.work_time != '') {
                    let diff = new Date(this.formdDatas.work_time[1]).getTime() - new Date(this.formdDatas.work_time[0]).getTime();
                    let days = Math.floor(diff / (24 * 3600 * 1000));

                    let end = new Date(new Date(this.formdDatas.work_time[0]).getTime() + (this.helpDatas.task_day - 1) * 24 * 3600 * 1000);
                    let m = (end.getMonth() + 1 < 10 ? '0' + (end.getMonth() + 1) : end.getMonth() + 1) + '-';
                    let day = (end.getDate() < 10 ? '0' + (end.getDate()) : end.getDate());

                    if ((days + 1) != Number(this.helpDatas.task_day)) {
                        this.formdDatas.work_time[1] = end.getFullYear() + '-' + m + day;

                        this.$message.error("项目周期时间与计划天数不符");
                    }
                }
            }
            this.formdDatas.explain != ""
                ? (this.nodeClass.do_explain = true)
                : (this.nodeClass.do_explain = false); // 项目说明完成检查
            this.formdDatas.annex != ""
                ? (this.nodeClass.do_annex = true)
                : (this.nodeClass.do_annex = false); // 项目附件完成检查
            this.formdDatas.end_time != ""
                ? (this.nodeClass.do_end_time = true)
                : (this.nodeClass.do_end_time = false); // 检查报名截止时间完成
            this.formdDatas.area_limit != ""
                ? (this.nodeClass.do_area_limit = true)
                : (this.nodeClass.do_area_limit = false); // 检查极客报名地址限制完成
            this.formdDatas.work_area != ""
                ? (this.nodeClass.do_work_area = true)
                : (this.nodeClass.do_work_area = false); // 检查工作地址完成
            this.formdDatas.geeks_type != ""
                ? (this.nodeClass.do_geeks_type = true)
                : (this.nodeClass.do_geeks_type = false); // 检查极客身份类型限制完成
            this.formdDatas.introduce != ""
                ? (this.nodeClass.do_introduce = true)
                : (this.nodeClass.do_introduce = false); // 检查成果要求说明完成

            this.formdDatas.geeks_credentials != ""
                ? (this.nodeClass.do_geeks_credentials = true)
                : (this.nodeClass.do_geeks_credentials = false); // 检查资质要求完成

            this.formdDatas.work_time != ""
                ? (this.nodeClass.do_work_time = true)
                : (this.nodeClass.do_work_time = false); // 检查预计工期完成

            (this.formdDatas.demand_annex == 1 &&
                this.formdDatas.demand_explain != "") ||
                this.formdDatas.demand_annex == 0
                ? (this.nodeClass.do_demand_annex = true)
                : (this.nodeClass.do_demand_annex = false); // 检查项目报名附件设定完成
            (this.formdDatas.is_bond == 1 && this.formdDatas.bond > 0) ||
                this.formdDatas.is_bond == "0"
                ? (this.nodeClass.do_is_bond = true)
                : (this.nodeClass.do_is_bond = false); // 检查履约保证金完成
            this.formdDatas.phone != "" && this.formdDatas.contact != ""
                ? (this.nodeClass.do_contact = true)
                : (this.nodeClass.do_contact = false); // 检查联系人完成
            this.statisticPotin();
            this.local_save(); //缓存表单
        },

        //右边进度节点分数
        statisticPotin() {
            //变更完成得分
            this.point = 0;
            if (this.nodeClass.do_task_name == true) this.point += 5;
            if (this.nodeClass.do_speciality_type == true) this.point += 5;
            if (this.nodeClass.do_task_type == true) this.point += 5;
            if (this.nodeClass.do_task_remuneration == true) this.point += 10;
            if (this.nodeClass.do_explain == true) this.point += 10;
            if (this.nodeClass.do_annex == true) this.point += 5;
            if (this.nodeClass.do_end_time == true) this.point += 5;
            if (this.nodeClass.do_area_limit == true) this.point += 5;
            if (this.nodeClass.do_work_area == true) this.point += 5;
            if (this.nodeClass.do_geeks_type == true) this.point += 5;
            if (this.nodeClass.do_introduce == true) this.point += 10;
            if (this.nodeClass.do_geeks_credentials == true) this.point += 5;
            if (this.nodeClass.do_work_time == true) this.point += 5;
            if (this.nodeClass.do_demand_annex == true) this.point += 5;
            if (this.nodeClass.do_is_bond == true) this.point += 5;
            if (this.nodeClass.do_contact == true) this.point += 10;
        }
    },

    created() {
        let _this = this;
        let tk = _this.$route.query.tk;

        if (_this.userInfo) {
            if (_this.userInfo.isLogin == false) {
                _this.$message.error("请登录雇主账户后再发布项目");
                this.$router.push("/login/index?type=login");
            }
            if (_this.userInfo.info.user_type == 1) {
                _this.$message.error("极客不允许发布项目");
                this.$router.push("/");
            }
            if (_this.userInfo.config.is_atte == '1' && _this.userInfo.info.user_type == 2 && _this.userInfo.info.attestation != 2) {
                _this.$message.error("请认证后再发布项目");
                this.$router.push("/user/attest");
            }
        } else {
            _this.$message.error("请登录雇主账户后再发布项目");
            this.$router.push("/login/index?type=login");
        }

        this.getArears(); //获取区域列表
        this.getTypes(); //获取服务类型

        //获取表单数据
        let local_data = sessionStorage.getItem("_task_detail_");
        if (local_data) {
            let task_info = JSON.parse(local_data);
            if (task_info.task_name) {
                _this.formdDatas = task_info;

                if (task_info.task_type == 3) {
                    _this.helpDatas.task_day = task_info.task_day;
                }
                if (task_info.task_type == 1) {
                    let money = task_info.remuneration.split("-");
                    this.helpDatas.remuneration_min = money[0]; //项目金额最小金额
                    this.helpDatas.remuneration_max = money[1]; //项目金额最大金额
                }
            } else {
                this.taskDetail(tk); //获取最新项目信息
            }
        } else {
            this.taskDetail(tk); //获取最新项目信息
        }

        if (_this.formdDatas.task_type == 1) {
            //竞价
            _this.remuneration_desc =
                "雇主设定酬金区间，工程极客报名时需进行报价，酬金不能低于100元。";
        }

        if (parseInt(_this.formdDatas.task_type) == 2) {
            //比选
            _this.remuneration_desc =
                "雇主设置酬金，工程极客报名时无需报价。酬金不能低于100元。";
        }

        if (_this.formdDatas.task_type == 3) {
            //日薪
            _this.remuneration_desc =
                "雇主设定每天的酬金，工程极客报名时无需报价，每日酬金不能低于200元。";
        }
    },

    filters: {
        getLabel(value, valArr) {
            // 单个select 筛选label值,value：需要筛选的value，如12345，valArr，被筛选的数据源，如[{value:'12345',label:'北京'}]
            return valArr.filter(arr => value == arr.value)[0].label;
        }
    }
};
</script>

<style lang="less" scoped>
@import "../../styles/ucattestation.less";
@import "../../styles/publish-task.less";
.publish_sec {
    .min-form-bd {
        margin-top: 10px;
    }
    .info-inner {
        padding: 0 40px;
    }
    .user-introduce {
        margin: 10px -10px 0;
    }
    /deep/ .jk-icon-yiwen {
        margin-top: 40px;
        font-weight: normal;
        color: #999;
    }
    /deep/.el-form-item__content {
        line-height: 40px;
    }
    .el-range-editor.el-input__inner {
        padding: 0 10px;
    }
    .el-tag + .el-tag {
        margin-left: 10px;
    }
    /deep/.width-100 .el-input {
        width: 100%;
        height: 40px;
    }
    /deep/.el-input .el-input__inner {
        line-height: 40px;
    }
    .form-basisinfo {
        padding: 0 40px;
    }
    .steps .info-card-panner {
        padding: 20px 0;
        border: none;
    }
    .form-cont {
        border: none;
    }
    .button-new-tag {
        margin-left: 10px;
        height: 32px;
        line-height: 30px;
        padding-top: 0;
        padding-bottom: 0;
    }
    .input-new-tag {
        width: 90px;
        margin-left: 10px;
        vertical-align: bottom;
    }
    .panner-bd {
        overflow: hidden;
    }
    .step3-info .panner-bd {
        height: auto !important;
    }
    /deep/ .el-select .el-input {
        width: 100%;
    }
    /deep/.el-select__caret,
    /deep/.el-icon-arrow-down {
        display: none;
    }
    /deep/ .el-icon-time {
        &::before {
            float: right;
            margin-right: 20px;
        }
        width: 100% !important;
        cursor: pointer;
        margin-top: 5px;
        margin-right: 0px;
    }
    /deep/.el-range__icon {
        width: auto !important;
    }
    /deep/.el-textarea__inner,
    /deep/.el-input__inner {
        height: 40px;
        line-height: 38px;
        border-radius: 0 !important;
    }
    /deep/.el-textarea__inner {
        height: 200px;
    }
    /deep/ .textarea-limit .el-textarea__inner {
        height: 100px;
    }
    /deep/.el-input {
        height: 41px;
    }

    .el-upload__tip {
        color: #9b9a9a !important;
    }
    .cont-btn {
        margin-top: 0px;
        text-align: left;
        padding-left: 30px;
    }
    .cont_btn {
        padding-left: 0px;
    }
    .el-button {
        background: #587ddc;
        color: white;
        border: none;
        &:hover {
            opacity: 0.9;
        }
    }
    .el-button.active {
        background: #409eff;
    }
    /deep/.is-success input,
    /deep/.is-success textarea,
    /deep/.is-success .el-input__inner {
        border: 1px solid #dcdfe6;
    }
    /deep/.is-success .task_time_box input {
        border: none !important;
    }
    /deep/.el-form-item__content {
        .el-icon-date {
            width: 100%;
            text-align: right;
            padding-right: 20px;
        }
    }
    /deep/ .el-form-item__label {
        height: 36px;
        line-height: 36px;
        color: #333;
        font-weight: 600;
    }
}

.jk-icon-gou {
}
</style>


